import { IconComponentProps } from ".";

export const IconPlayerPlay = (props: IconComponentProps) => {
  const width = props.width ? props.width : 1.5;
  const color = props.color ? props.color : "#ffffff";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-player-play"
      viewBox="0 0 24 24"
      strokeWidth={width}
      stroke={color}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M7 4v16l13 -8z" />
    </svg>
  );
};
