import * as React from "react";
import { SVGProps } from "react";

export const GoogleSignNa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={3.5} />
    <g clipPath="url(#a)">
      <path
        fill="#4285F4"
        d="M29.6 20.227c0-.709-.064-1.39-.182-2.045H20v3.868h5.382a4.6 4.6 0 0 1-1.996 3.018v2.51h3.232c1.891-1.742 2.982-4.305 2.982-7.35Z"
      />
      <path
        fill="#34A853"
        d="M20 30c2.7 0 4.964-.895 6.618-2.423l-3.232-2.509c-.895.6-2.04.955-3.386.955-2.605 0-4.81-1.76-5.595-4.123h-3.341v2.59A9.996 9.996 0 0 0 20 30Z"
      />
      <path
        fill="#FBBC04"
        d="M14.405 21.9c-.2-.6-.314-1.24-.314-1.9 0-.66.114-1.3.314-1.9v-2.59h-3.341A9.997 9.997 0 0 0 10 20c0 1.614.386 3.14 1.064 4.49l3.34-2.59Z"
      />
      <path
        fill="#E94235"
        d="M20 13.977c1.468 0 2.786.505 3.823 1.496l2.868-2.869C24.959 10.992 22.695 10 20 10c-3.91 0-7.29 2.24-8.936 5.51l3.34 2.59c.787-2.364 2.991-4.123 5.596-4.123Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M10 10h20v20H10z" />
      </clipPath>
    </defs>
  </svg>
);
