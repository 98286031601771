import { SVGProps } from "react";

export const CloseBtnIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#FEFDFE" d="M4 6.667 6.667 4 20 17.334 17.333 20z" />
    <path fill="#FEFDFE" d="M6.666 20 4 17.333 17.333 4 20 6.667z" />
  </svg>
);
